import { json, type MetaFunction } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { AboutMeComponent } from '#app/components/about-me/about-me.tsx'
import { type AboutMeLoaderType } from '#app/data/selectors/aboutMeSelector.ts'
import { read } from '#app/utils/aboutMe.server.ts'
import { getMetaTitle } from '#app/utils/meta'

export const loader = async () => {
	const aboutMe = await read()
	return json(aboutMe)
}

export default function Index() {
	const {
		body,
		selectedFrameworks,
		profilePhoto,
		cvUrl,
		linkedinUrl,
		subHeader,
		header,
	} = useLoaderData<AboutMeLoaderType>()

	return (
		<main className="relative flex w-full flex-col items-center justify-center text-center align-middle  drop-shadow-small">
			<AboutMeComponent
				body={body ?? ''}
				profilePhoto={profilePhoto}
				subHeader={subHeader ?? ''}
				header={header ?? ''}
				frameworks={selectedFrameworks ?? []}
				linkedinUrl={linkedinUrl}
				cvUrl={cvUrl}
			/>
		</main>
	)
}

export const meta: MetaFunction = () => [
	{ title: getMetaTitle('Senior Software Engineer') },
]
